/*@mixin button-bg($color,$background-color,$padding,$margin,$letter-spacing,$font-weight,$font-size,$case){
	color:$color;
	background-color:$background-color;
	padding: $padding;
    margin: $margin;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight;
    font-size: $font-size;
    text-transform: $case;
    @include transition-all(all 0.3s ease-out);
}*/
/*Dashboard page*/
#contentbuilder {
  border: rgba(0, 0, 0, 0.25) 1px dashed; }

.tag-section {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  margin-top: 0 !important;
  max-height: 76vh;
  padding-top: 10px;
  padding-right: 2px;
  padding-bottom: 10px;
  padding-left: 2px; }
  .tag-section .item-text-wrap {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    -webkit-box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.34);
    box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.34); }
  .tag-section .listvariable {
    border: 1px solid #cccccc;
    position: relative;
    z-index: 2;
    display: block;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    font-size: 14px;
    text-align: center; }
  .tag-section .var-not-inserted {
    background-color: #f2f2f2;
    /*border-left: 10px solid red*/
    border-top: 0px;
    color: #333333;
    border: 1px solid #cccccc; }
  .tag-section .var-inserted {
    background-color: rgba(0, 255, 0, 0.16);
    color: #333333;
    border-top: 4px solid #49B527; }
  .tag-section .tag-details {
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px; }
    .tag-section .tag-details label {
      display: block;
      text-align: left; }
    .tag-section .tag-details span {
      position: absolute;
      top: 5px;
      right: 5px; }
  .tag-section .variation-form label {
    display: block;
    text-align: left; }
  .tag-section .variation-form input.form-control {
    float: left;
    width: 76% !important;
    margin-right: 2%; }
    @media (max-width: 1170px) {
      .tag-section .variation-form input.form-control {
        width: 100% !important;
        margin-right: 0%; } }
  .tag-section .variation-form button {
    float: left;
    width: 22%; }
    @media (max-width: 1170px) {
      .tag-section .variation-form button {
        width: 100% !important;
        margin-top: 5px; } }
  .tag-section .variation-form p {
    font-size: 13px; }
  .tag-section .additemDivier {
    padding-top: 8px;
    padding-right: 0px;
    padding-bottom: 8px;
    padding-left: 0px;
    min-height: 30px;
    color: #333333;
    font-weight: 500; }
  .tag-section .title-main {
    color: #333333;
    font-size: 16px;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 0px;
    font-weight: bold;
    clear: both; }
  .tag-section .add-variation {
    min-height: 50px;
    overflow: visible;
    clear: both; }
    .tag-section .add-variation .itemvar {
      text-align: left;
      width: 100%;
      background-color: #ffffff;
      color: #333333;
      position: relative;
      z-index: 2;
      display: block;
      margin-bottom: -1px;
      padding-top: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
      padding-left: 16px;
      border: 1px solid #cccccc;
      font-size: 16px;
      box-sizing: border-box;
      position: relative; }
      .tag-section .add-variation .itemvar:before {
        content: "\f054";
        font-family: FontAwesome;
        display: block;
        width: 20px;
        height: 20px;
        float: right;
        margin: 0 6px 0 0; }
  .tag-section .btn-primary {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px; }
  .tag-section .addspace {
    margin-bottom: 3px; }

.variation-sec .btn-secondary {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  width: 100%; }

.red-block p {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px; }
