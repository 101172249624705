/*@mixin button-bg($color,$background-color,$padding,$margin,$letter-spacing,$font-weight,$font-size,$case){
	color:$color;
	background-color:$background-color;
	padding: $padding;
    margin: $margin;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight;
    font-size: $font-size;
    text-transform: $case;
    @include transition-all(all 0.3s ease-out);
}*/
.row.banner-sec.list-template {
  display: block; }

.banner-sec [class*="col-sm-"] {
  /*min-height:300px;*/
  position: relative;
  padding-top: 15px; }

.banner-sec img {
  width: 100%;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-left: 0px; }

/*
.previewbtn,.openTemplatebtn {
    @include padding-all(0px, 5px, 0px, 10px);
}*/
.listtemp {
  width: 100%;
  color: black;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px; }

.stepOne .stepOne-form {
  margin: 0 auto;
  width: auto;
  min-height: 200px; }
  .stepOne .stepOne-form input {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px; }

.stepOne .settings-form {
  margin: 0 auto;
  width: auto;
  min-height: 100px;
  text-align: center; }
  .stepOne .settings-form input {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px; }

.create-proj .modal-content img {
  margin: 0 auto;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px; }
