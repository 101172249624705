/*@mixin button-bg($color,$background-color,$padding,$margin,$letter-spacing,$font-weight,$font-size,$case){
	color:$color;
	background-color:$background-color;
	padding: $padding;
    margin: $margin;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight;
    font-size: $font-size;
    text-transform: $case;
    @include transition-all(all 0.3s ease-out);
}*/
/*Template page*/
.recent-project h1.project-list {
  margin-top: 15px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px; }

.recent-project h4 {
  padding-top: 15px;
  padding-right: 0px;
  padding-bottom: 5px;
  padding-left: 0x; }

.recent-project .project-list {
  outline: none;
  cursor: pointer; }

.recent-project .project-list .box {
  position: relative;
  z-index: 1000;
  padding: 15px;
  background: #2b303a;
  margin-bottom: 20px; }
  .recent-project .project-list .box .hiddenn {
    opacity: 0; }
  .recent-project .project-list .box .visible {
    opacity: 1; }
  .recent-project .project-list .box .template-box {
    position: relative; }
    .recent-project .project-list .box .template-box h5 {
      text-align: left;
      color: #ffffff;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 1.6;
      color: #ffffff;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 105px; }
      .recent-project .project-list .box .template-box h5 i {
        margin-top: 0px;
        margin-right: 5px;
        margin-bottom: 0px;
        margin-left: 0px; }
    .recent-project .project-list .box .template-box span.rename {
      padding-top: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.4); }
    .recent-project .project-list .box .template-box span.delete {
      padding-top: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.4); }
    .recent-project .project-list .box .template-box span.clone {
      padding-top: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.4); }
    .recent-project .project-list .box .template-box div.actions {
      position: absolute;
      right: 0;
      top: -3px;
      width: auto;
      height: 100%;
      text-align: right; }
  .recent-project .project-list .box:hover {
    background: #272b34; }
  .recent-project .project-list .box .more-icon {
    position: absolute;
    right: 10px;
    top: 10px; }
    .recent-project .project-list .box .more-icon .btn {
      background: rgba(0, 0, 0, 0.4); }
      .recent-project .project-list .box .more-icon .btn i {
        color: #ffffff; }

.recent-project .project-list-message {
  text-align: center; }
