/*@mixin button-bg($color,$background-color,$padding,$margin,$letter-spacing,$font-weight,$font-size,$case){
	color:$color;
	background-color:$background-color;
	padding: $padding;
    margin: $margin;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight;
    font-size: $font-size;
    text-transform: $case;
    @include transition-all(all 0.3s ease-out);
}*/
.page-container {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  width: 100%;
  display: table;
  height: 100%;
  table-layout: fixed; }
  .page-container.no-sidebar {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px; }
  .page-container .sidebar-menu {
    width: 100%;
    /*
		background: #58bd91;
		background: -moz-linear-gradient(top,  #58bd91 0%, #77cad5 100%);
		background: -webkit-linear-gradient(top,  #58bd91 0%,#77cad5 100%);
		background: linear-gradient(to bottom,  #58bd91 0%,#77cad5 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58bd91', endColorstr='#77cad5',GradientType=0 );*/
    -webkit-box-shadow: 1px 0px 11px 0px rgba(156, 156, 156, 0.6);
    -moz-box-shadow: 1px 0px 11px 0px rgba(156, 156, 156, 0.6);
    box-shadow: 1px 0px 11px 0px rgba(156, 156, 156, 0.6); }
    .page-container .sidebar-menu #main-menu li {
      border-bottom: 1px solid #000000; }
      .page-container .sidebar-menu #main-menu li:first-child {
        border-top: 1px solid #000000; }
      .page-container .sidebar-menu #main-menu li a {
        font-size: 18px;
        cursor: pointer;
        font-weight: 500;
        color: #000000;
        padding-top: 21px;
        padding-right: 22px;
        padding-bottom: 22px;
        padding-left: 22px; }
        .page-container .sidebar-menu #main-menu li a:hover {
          background-color: #2b303a;
          color: #ffffff; }
        .page-container .sidebar-menu #main-menu li a:focus {
          background-color: #2b303a;
          color: #ffffff; }
    .page-container .sidebar-menu #main-menu li.active a {
      background-color: #2b303a;
      color: #ffffff; }
    .page-container .sidebar-menu .logo-env {
      width: 100%;
      padding-top: 26px;
      padding-right: 28px;
      padding-bottom: 26px;
      padding-left: 28px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
      .page-container .sidebar-menu .logo-env .logo {
        display: block;
        vertical-align: middle;
        white-space: nowrap;
        float: left;
        margin-top: 0px;
        margin-right: 15px;
        margin-bottom: 0px;
        margin-left: 0px; }
      .page-container .sidebar-menu .logo-env:after {
        content: " ";
        display: table;
        clear: both; }

/*** New top navigation ***/
/*** Removed default grey border of toggle button ***/
.navbar-default .navbar-toggle {
  border: 0;
  background-color: inherit !important; }

.page-container .top-navigation .navbar-brand {
  padding: 5px 15px; }
  .page-container .top-navigation .navbar-brand .site-version {
    display: block;
    text-align: center;
    margin-top: -10px; }

.page-container .top-navigation #header-menu li.active a, .page-container .top-navigation #header-menu li a:focus, .page-container .top-navigation #header-menu li a:hover {
  background-color: #2b303a;
  color: #fff; }

.page-container .top-navigation #header-menu li a {
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
  color: #000000;
  padding: 21px 22px 22px; }

.page-container .top-navigation .upgrade-btn {
  background: #d6ffd6;
  padding: 10px 15px 10px !important;
  box-shadow: 0px 3px 3px 2px #b5d5aa;
  margin: 10px; }

.page-container .header-nav {
  background-color: #bce0b0;
  border-color: #bce0b0;
  border-radius: 0;
  margin-bottom: 0; }

/* sidebar collapse open*/
.page-container .sidebar-menu .logo-env > div.logo {
  display: block; }

.page-container .sidebar-menu .logo-env > div.sidebar-collapse {
  position: relative;
  float: right; }
  .page-container .sidebar-menu .logo-env > div.sidebar-collapse a {
    border: 1px solid #333333;
    color: #333333;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    display: inline-block;
    text-align: center;
    line-height: 1;
    font-size: 20px;
    font-weight: 300;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box; }
    .page-container .sidebar-menu .logo-env > div.sidebar-collapse a:hover {
      background-color: rgba(69, 74, 84, 0.4); }

.page-container .sidebar-menu .logo-env > div.sidebar-mobile-menu {
  position: relative;
  float: right; }
  .page-container .sidebar-menu .logo-env > div.sidebar-mobile-menu a {
    border: 1px solid #333333;
    color: #333333;
    display: inline-block;
    text-align: center;
    line-height: 1;
    font-size: 20px;
    font-weight: 300;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box; }
    .page-container .sidebar-menu .logo-env > div.sidebar-mobile-menu a:hover {
      background-color: rgba(69, 74, 84, 0.4); }

/*sidebar-collapsed Close*/
body .page-container.sidebar-collapsed .sidebar-menu {
  z-index: 100;
  width: 100%; }
  body .page-container.sidebar-collapsed .sidebar-menu .logo-env > {
    display: block;
    padding-top: 25px;
    padding-right: 10px;
    padding-bottom: 25px;
    padding-left: 10px; }
    body .page-container.sidebar-collapsed .sidebar-menu .logo-env > div.logo {
      display: none;
      width: 0; }
  body .page-container.sidebar-collapsed .sidebar-menu #main-menu {
    list-style: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px; }
    body .page-container.sidebar-collapsed .sidebar-menu #main-menu li.active a {
      background-color: #2b303a;
      color: #ffffff; }
    body .page-container.sidebar-collapsed .sidebar-menu #main-menu li {
      border-bottom: none;
      position: relative;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      font-size: 12px;
      -webkit-transition: 200ms opacity ease-in-out;
      -moz-transition: 200ms opacity ease-in-out;
      -o-transition: 200ms opacity ease-in-out;
      transition: 200ms opacity ease-in-out; }
      body .page-container.sidebar-collapsed .sidebar-menu #main-menu li:first-child {
        border-top: 1px solid #000000; }
      body .page-container.sidebar-collapsed .sidebar-menu #main-menu li a {
        position: relative;
        font-size: 18px;
        cursor: pointer;
        font-weight: 500;
        display: block;
        z-index: 2;
        border-bottom: 1px solid #000000;
        padding-top: 22px;
        padding-right: 22px;
        padding-bottom: 22px;
        padding-left: 22px; }
        body .page-container.sidebar-collapsed .sidebar-menu #main-menu li a span {
          -webkit-transition: 350ms opacity ease-in-out;
          -moz-transition: 350ms opacity ease-in-out;
          -o-transition: 350ms opacity ease-in-out;
          transition: 350ms opacity ease-in-out; }
          body .page-container.sidebar-collapsed .sidebar-menu #main-menu li a span:not(.badge) {
            background-color: #2b303a;
            display: block;
            position: absolute;
            top: 0;
            left: 0px;
            margin-top: -1px;
            border-top: 1px solid #000000;
            border-bottom: 1px solid #000000;
            border-right: 1px solid #000000;
            width: 0px;
            min-width: 65px !important;
            visibility: visible;
            padding-top: 12px;
            padding-right: 10px;
            padding-bottom: 12px;
            padding-left: 10px;
            -webkit-border-radius: 0 3px 3px 0;
            -moz-border-radius: 0 3px 3px 0;
            -ms-border-radius: 0 3px 3px 0;
            border-radius: 0 3px 3px 0;
            -webkit-background-clip: padding-box;
            -moz-background-clip: padding-box;
            background-clip: padding-box;
            opacity: 0;
            zoom: 1; }
        body .page-container.sidebar-collapsed .sidebar-menu #main-menu li a span.badge {
          position: absolute;
          right: -240px;
          top: 10px;
          display: none; }
        body .page-container.sidebar-collapsed .sidebar-menu #main-menu li a i {
          top: 1px;
          display: inline-block;
          font-size: 20px;
          position: relative;
          margin-right: 5px; }
        body .page-container.sidebar-collapsed .sidebar-menu #main-menu li a:hover {
          background-color: #2b303a;
          color: #ffffff; }
        body .page-container.sidebar-collapsed .sidebar-menu #main-menu li a:focus {
          background-color: #2b303a;
          color: #ffffff; }
      body .page-container.sidebar-collapsed .sidebar-menu #main-menu li:hover a span {
        visibility: visible;
        zoom: 1;
        opacity: 1;
        width: 250px;
        left: 65px;
        padding-top: 23px;
        padding-right: 10px;
        padding-bottom: 23px;
        padding-left: 10px; }

.page-container .main-content {
  display: block;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px; }

.page-container.sidebar-collapsed {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px; }
  .page-container.sidebar-collapsed .logo-env {
    padding-top: 27px;
    padding-right: 16px;
    padding-bottom: 26px;
    padding-left: 10px; }

/*sidebar-collapsed Close END*/
.menu-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  position: relative;
  z-index: 99;
  border-radius: 2px; }
  .menu-icon:hover {
    background: rgba(0, 0, 0, 0.4); }
  .menu-icon .line {
    display: inline-block;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
    width: 20px;
    height: 2px;
    background: #373e4a;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: background 0.6s 0s ease; }
    .menu-icon .line:after, .menu-icon .line:before {
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      border-radius: 2px;
      transition: top 0.2s 0.4s ease, -webkit-transform 0.3s ease;
      content: '';
      position: absolute;
      top: -6px;
      left: 0;
      background: #373e4a;
      display: inline-block;
      width: 20px;
      height: 2px; }
    .menu-icon .line:before {
      top: 6px; }

.menu-open .main {
  left: 200px; }

.menu-open .menu-icon .line {
  background: transparent; }
  .menu-open .menu-icon .line:before, .menu-open .menu-icon .line:after {
    top: 0;
    transition: top 0.2s ease, -webkit-transform 0.2s 0.4s ease;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }
  .menu-open .menu-icon .line:after {
    transform: rotate3d(0, 0, 1, -45deg);
    -webkit-transform: rotate3d(0, 0, 1, -45deg); }
  @media (max-width: 991px) {
    .menu-open .menu-icon .line {
      background: transparent; }
      .menu-open .menu-icon .line:before, .menu-open .menu-icon .line:after {
        top: 0;
        transition: top 0.2s ease, -webkit-transform 0.2s 0.4s ease;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%; }
      .menu-open .menu-icon .line:after {
        transform: rotate3d(0, 0, 1, -45deg);
        -webkit-transform: rotate3d(0, 0, 1, -45deg); } }

.mySidenavOpen {
  visibility: visible;
  opacity: 1;
  width: 281px !important; }

.mySidenavClose {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear; }

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  background-color: #bce0b0;
  overflow-x: hidden;
  padding-top: 60px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out; }
  .sidenav .button-anchor {
    text-decoration: none;
    font-size: 32px;
    color: #818181;
    display: block;
    transition: 0.3s;
    position: absolute;
    top: 0px;
    right: 10px; }
  .sidenav #chat {
    position: absolute !important;
    top: 48px !important;
    z-index: 100 !important;
    display: block; }
    .sidenav #chat .btn {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 5px;
      margin-left: 0px; }

.c-hamburger {
  display: block;
  position: relative;
  overflow: hidden;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 5px;
  padding-left: 0px;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s; }

.c-hamburger--htra.is-active {
  background: rgba(0, 0, 0, 0.5); }

.c-hamburger--htra {
  background: rgba(0, 0, 0, 0.4); }

.c-hamburger--htra.is-active span::before {
  top: 0;
  transform: translateX(-6px) translateY(1px) rotate(-44deg); }

.c-hamburger--htla.is-active span::after,
.c-hamburger--htla.is-active span::before,
.c-hamburger--htra.is-active span::after,
.c-hamburger--htra.is-active span::before {
  width: 56%; }

.c-hamburger--htra span::before {
  transform-origin: left top 0;
  transition: transform 0.3s ease 0s, width 0.3s ease 0s, top 0.3s ease 0s; }

.c-hamburger span::before {
  top: -7px; }

.c-hamburger span:after, .c-hamburger span:before {
  background-color: #fff;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%; }

.c-hamburger--htra.is-active span::after {
  bottom: 0;
  transform: translateX(-6px) translateY(-1px) rotate(45deg); }

.c-hamburger--htla.is-active span:after, .c-hamburger--htla.is-active span:before {
  width: 56%; }

.c-hamburger--htra.is-active span:after, .c-hamburger--htra.is-active span:before {
  width: 56%; }

.c-hamburger--htra span::after {
  transform-origin: left bottom 0;
  transition: transform 0.3s ease 0s, width 0.3s ease 0s, bottom 0.3s ease 0s; }

.c-hamburger span::after {
  bottom: -7px; }

.c-hamburger span:after, .c-hamburger span:before {
  background-color: #fff;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%; }

.c-hamburger--htra.is-active span {
  transform: rotate(180deg) !important; }

.c-hamburger--htra.is-active span {
  transition: transform 0.3s ease 0s; }

.c-hamburger span {
  background: #fff none repeat scroll 0 0;
  display: block;
  height: 3px;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 19px; }

.c-hamburger:focus {
  outline: none; }
