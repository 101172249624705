.license-page {
  background-color: #14171f;
  background-image: -webkit-gradient(left top, left bottom, color-stop(0, #14171f), color-stop(100%, #2b303a));
  background-image: linear-gradient(180deg, #14171f 0, #2b303a);
  min-height: 100%;
  width: 100%;
  height: auto; }

.input-group-addon, .license-page .license-header {
  color: #fff;
  background-color: #58bd91;
  background-image: -webkit-gradient(left top, left bottom, color-stop(0, #58bd91), color-stop(100%, #77cad5));
  background-image: linear-gradient(180deg, #58bd91 0, #77cad5); }

.license-page .license-header {
  padding: 50px 0;
  text-align: center;
  color: red; }

.license-page .license-form {
  zoom: 1;
  top: 0;
  opacity: 1;
  transition: all .55s ease-in-out;
  position: relative;
  padding: 60px 0 0;
  background: #303641; }

.license-page .license-content {
  position: relative;
  width: 320px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  transition: all .55s ease-in-out; }

.license-page .license-form .form-group .input-group {
  background: #373e4a;
  border: 1px solid #373e4a;
  padding: 7px;
  transition: all .3s ease-in-out;
  border-radius: 3px;
  background-clip: padding-box; }

.license-page .license-form .form-group .input-group .input-group-addon {
  background: transparent;
  border: none; }

.license-page .license-form .form-group .input-group .form-control:last-child {
  border-radius: 3px; }

.license-page .license-form .form-group .input-group .form-control {
  border: 0 !important;
  background: #f9febc;
  color: #1f232b; }

.license-page .license-form .form-group .btn-license {
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  background-color: #90cc7d;
  border: 1px solid #454a54;
  border-radius: 3px;
  padding: 15px 20px; }

.license-page .license-form .form-group .btn-license i {
  float: right; }
