/*@mixin button-bg($color,$background-color,$padding,$margin,$letter-spacing,$font-weight,$font-size,$case){
	color:$color;
	background-color:$background-color;
	padding: $padding;
    margin: $margin;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight;
    font-size: $font-size;
    text-transform: $case;
    @include transition-all(all 0.3s ease-out);
}*/
.loaderstyle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding-top: 25%;
  border: 0;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px; }

.info {
  display: inline-block;
  cursor: default;
  color: #b3cea9;
  font-size: 15px;
  font-weight: 500;
  padding: 8px;
  padding-right: 36px; }

.bradcrumb-top {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  background-color: #14171f;
  background-image: -webkit-gradient(left top, left bottom, color-stop(0%, #14171f), color-stop(100%, #2b303a));
  background-image: -webkit-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: -moz-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: -ms-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: -o-linear-gradient(top, #14171f 0%, #2b303a 100%);
  background-image: linear-gradient(to bottom, #14171f 0%, #2b303a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(start-colourStr='#14171f', end-colourStr='#2b303a',GradientType=0 );
  display: inline-block;
  width: 100%;
  position: relative; }
  .bradcrumb-top h1 {
    float: left !important;
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    color: #ffffff;
    font-size: 22px; }
    @media (max-width: 991px) {
      .bradcrumb-top h1 {
        margin-left: 15px;
        width: 100%; } }
    @media (max-width: 1170px) {
      .bradcrumb-top h1 {
        display: none; } }
  .bradcrumb-top .project-title {
    float: left;
    padding-top: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff; }
    @media (min-width: 1024px) {
      .bradcrumb-top .project-title {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        margin-top: 0px;
        margin-right: 5px;
        margin-bottom: 5px;
        margin-left: 5px; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      .bradcrumb-top .project-title {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        margin-top: 10px;
        margin-right: 5px;
        margin-bottom: 10px;
        margin-left: 10px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .bradcrumb-top .header-menu .pull-right {
      margin-left: 15px;
      float: right !important; } }
  @media (max-width: 991px) {
    .bradcrumb-top .header-menu .pull-right {
      margin-left: 15px;
      float: right !important; } }
  .bradcrumb-top ul.list-inline {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px; }
    .bradcrumb-top ul.list-inline > li {
      float: left;
      padding: 0;
      line-height: normal;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px; }
      @media (max-width: 1230px) {
        .bradcrumb-top ul.list-inline > li {
          margin-top: 0px;
          margin-right: 0px;
          margin-bottom: 0px;
          margin-left: 0px; } }
      @media (max-width: 1060px) {
        .bradcrumb-top ul.list-inline > li {
          padding-top: 0px;
          padding-right: 0px;
          padding-bottom: 0px;
          padding-left: 0px; } }
      @media (max-width: 991px) {
        .bradcrumb-top ul.list-inline > li {
          margin-top: 0px;
          margin-right: 0px;
          margin-bottom: 0px;
          margin-left: 0px;
          padding-top: 0px;
          padding-right: 0px;
          padding-bottom: 0px;
          padding-left: 0px; } }
      .bradcrumb-top ul.list-inline > li li a {
        border-radius: 0;
        margin: 0; }
      .bradcrumb-top ul.list-inline > li li a.btn-primary {
        text-transform: uppercase;
        border: 1px solid #4eb5d4;
        font-size: 14px;
        font-weight: 600;
        user-select: none;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out; }
        .bradcrumb-top ul.list-inline > li li a.btn-primary:hover {
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        @media (max-width: 991px) {
          .bradcrumb-top ul.list-inline > li li a.btn-primary {
            font-size: 13px; } }
      .bradcrumb-top ul.list-inline > li.current a.btn-primary {
        font-size: 14px; }
        @media (max-width: 991px) {
          .bradcrumb-top ul.list-inline > li.current a.btn-primary {
            font-size: 13px; } }
      .bradcrumb-top ul.list-inline > li:last-child {
        margin-right: 15px; }
  .bradcrumb-top .btn-primary {
    text-transform: uppercase;
    background: none;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    user-select: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0; }
    .bradcrumb-top .btn-primary:hover {
      background-color: #58a340;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    @media (min-width: 768px) and (max-width: 1023px) {
      .bradcrumb-top .btn-primary {
        font-size: 13px; } }
